import {randString} from "@/utils/libs";
import {defineStore} from 'pinia';
import Storage from "good-storage";

/**
 * storage handler
 */

export const useSessionStore = defineStore('session', {
    state: () => {
        return {
            avatar: ''
        };
    },
    getters: {},
    actions: {
        setAvatar(avatar) {
            this.avatar = avatar;
        }
    }
});

const SessionIDKey = 'SESSION_ID';
const UserTokenKey = "Authorization";
const AdminTokenKey = "Admin-Authorization"
const USER_CACHE_KEY = "user_info";

export function getSessionId() {
    let sessionId = Storage.get(SessionIDKey)
    if (!sessionId) {
        sessionId = randString(42)
        setSessionId(sessionId)
    }
    return sessionId
}

export function removeSessionId() {
    if (Storage.get(SessionIDKey)) {
        Storage.remove(SessionIDKey)
    }
}

export function setSessionId(sessionId) {
    Storage.set(SessionIDKey, sessionId)
}

export function getUserToken() {
    return Storage.get(UserTokenKey) || ''
}

export function setUserToken(token) {
    Storage.set(UserTokenKey, token)
}

export function removeUserToken() {
    Storage.remove(UserTokenKey)
}

export function getAdminToken() {
    return Storage.get(AdminTokenKey)
}

export function setAdminToken(token) {
    Storage.set(AdminTokenKey, token)
}

export function removeAdminToken() {
    Storage.remove(AdminTokenKey)
}

export const setUserToCache = (user) => {
    Storage.set(USER_CACHE_KEY, JSON.stringify(user));
};
// 从缓存中获取用户信息
export const getUserFromCache = () => {
    const user = Storage.get(USER_CACHE_KEY);
    return user ? JSON.parse(user) : {};
};

// 移除用户信息缓存
export const removeUserFromCache = () => {
    Storage.remove(USER_CACHE_KEY);
};