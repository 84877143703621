// plugins/vant.js
import {
    Button,
    Cell,
    CellGroup,
    ConfigProvider,
    Dialog,
    DropdownItem,
    DropdownMenu,
    Field,
    Form,
    Icon,
    Image,
    List,
    NavBar,
    Notify,
    Picker,
    Popup,
    Search,
    ShareSheet,
    Sticky,
    SwipeCell,
    Switch,
    Tabbar,
    TabbarItem,
    Tag,
    TextEllipsis,
    Uploader,
    Tabs,
    Tab,
    Checkbox,
    Popover,
    PullRefresh,
    CheckboxGroup,
    Loading,
} from "vant";

export default function useVant(app) {
    app
        .use(ConfigProvider)
        .use(Tabbar)
        .use(TabbarItem)
        .use(NavBar)
        .use(Search)
        .use(Cell)
        .use(Image)
        .use(TextEllipsis)
        .use(Notify)
        .use(Picker)
        .use(Popup)
        .use(List)
        .use(Form)
        .use(Field)
        .use(CellGroup)
        .use(Button)
        .use(DropdownMenu)
        .use(Icon)
        .use(DropdownItem)
        .use(Sticky)
        .use(SwipeCell)
        .use(Dialog)
        .use(ShareSheet)
        .use(Switch)
        .use(Uploader)
        .use(Tag)
        .use(Tabs)
        .use(Tab)
        .use(Checkbox)
        .use(Popover)
        .use(PullRefresh)
        .use(CheckboxGroup)
        .use(Loading);
}
