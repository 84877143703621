import {createApp} from 'vue';
import App from './App.vue';
import router from '@/router';
import {createPinia} from 'pinia';
import useElementPlus from '@/plugins/elementPlus';
import 'element-plus/dist/index.css';
import 'vant/lib/index.css';
import '@mdi/font/css/materialdesignicons.css';
import 'v3-waterfall/dist/style.css';
import '@/assets/css/makrdown.styl';
// 注册指令
import vdown from '@/directive/vdown';
import useVant from '@/plugins/vant';
import V3waterfall from 'v3-waterfall';

// 必须引入的核心
import Recorder from 'recorder-core';
// 引入 MP3 格式支持
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
// 可选插件支持
import 'recorder-core/src/extensions/waveview';
import 'default-passive-events';

const app = createApp(App);

// 全局注册插件
app.use(createPinia());
app.use(router);
useElementPlus(app); // 注册 Element Plus
app.use(V3waterfall);
useVant(app);

// 注册自定义指令
app.directive('down', vdown);

// 挂载应用
app.mount('#app');
